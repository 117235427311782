import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, } from '@/store';
const startRouteGuard = async (to, from, next) => {
    await mainStore.checkLoggedIn();
    if (mainStore.isLoggedIn) {
        if (to.path === '/login' || to.path === '/' || to.path === '/portal') {
            next('/main');
        }
        else {
            next();
        }
    }
    else if (mainStore.isLoggedIn === false) {
        if (to.path === '/' || to.path.startsWith('/main')) {
            next('/login');
        }
        else {
            next();
        }
    }
};
let Start = class Start extends Vue {
    beforeRouteEnter(to, from, next) {
        startRouteGuard(to, from, next);
    }
    beforeRouteUpdate(to, from, next) {
        startRouteGuard(to, from, next);
    }
};
Start = __decorate([
    Component
], Start);
export default Start;
